// import { reactive, toRefs } from "vue";
import { getMemberInfo } from "@/api/api";
import { useDispathStore } from "@/hook/useChangeStore";
export default function useGetMemberInfo(values) {
  // const inFo = reactive({
  //   relname: "",
  //   inductionTimeCount: "",
  //   companyLogo: "",
  // });
  getMemberInfo().then((res) => {
    if (res) {
      const { inductionTimeCount, relname, companyLogo, ...value } = res;
      useDispathStore("changeOneself", {
        inductionTimeCount,
        relname,
        companyLogo,
        ...value
      });
    }
  });
 
  // return toRefs(inFo);
}

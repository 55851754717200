const nodeEvn = process.env.NODE_ENV;
// 跳转夸夸
export function useGetKuaKuaUrl() {
  if(nodeEvn == "production"){
    return 'https://ybhuser.wanhuahengxin.com/login?'
  }else if(nodeEvn == "development"){
    return 'https://uatybhuser.wanhuahengxin.com/login?'
  }else {
    return 'https://devybhuser.wanhuahengxin.com/login?'
  }
}
// 跳转落地页
export function useGetLuoDiUrl() {
  if(nodeEvn == "production"){
    return 'https://ybhstore.wanhuahengxin.com'
  }else if (nodeEvn == "development") {
    return 'https://uatybhstore.wanhuahengxin.com'
  }else {
    return 'https://devybhstore.wanhuahengxin.com'
  }
}
// 跳转360
export function useGet360Url() {
  if(nodeEvn == "production"){
    return 'https://ybhcare.wanhuahengxin.com'
  }else if (nodeEvn == "development") {
    return 'https://uatybhcare.wanhuahengxin.com'
  }else {
    return 'https://devybhcare.wanhuahengxin.com'
  }
}
// 跳转新体检
export function useGetNewTiJianUrl() {
  if(nodeEvn == "production"){
    return 'https://new-health.wanhuahengxin.com'
  }else {
    return ' https://uatnew-health.wanhuahengxin.com'
  }
}
// 卡券按钮跳转
export function useGetVoucherUrl() {
  if(nodeEvn == "production"){
    return 'https://ybhecard.wanhuahengxin.com'
  }else {
    return 'https://testybhecard.wanhuahengxin.com'
  }
}

<template>
  <div class="login bg_white border_b fw_500">
    <div class="auto-home__main">
      <img
        class="auto-home__loading"
        src="/loading-spin.svg"
        alt="loading"
      />
      <div class="auto-home__title">加载中 请耐心等待</div>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import * as dd from "dingtalk-jsapi";
import {
  dingLogin,
  getCompanyConfigureByCompanyId,
  exchange,
  qywxLogin,
  fsLogin } from "@/api/api";
import { useLSSet, useLSRemove, useLSGet } from "@/hook/useLocalStorage";
import useGetUrlParam from "@/hook/useGetUrlParam";
import useGetMemberInfo from "@/hook/useGetMemberInfo";
import { useRouterReplace } from '@/hook/useRouterPush';
import { useGetKuaKuaUrl, useGetLuoDiUrl, useGetNewTiJianUrl } from '@/hook/useGetJumpUrl';
import { stringify } from 'qs';
import {Toast} from 'vant'
export default {
  name: "AutoLogin",
  setup() {
    const router = useRouter();
    // 去主页
    const goPage = (urlJson = {}) => {
      const { type,birthdayType } = router.currentRoute.value.query;
      const { cardId ,birthdayWallId} = urlJson;
      let path = '/';
      let query = {};
      if (cardId) {
        // 贺卡
        path = '/common';
        query = { cardId };
      }else if(birthdayWallId){
        if(birthdayType==='2'){
          // 生日墙红包
          path = '/redPacket';
          query = { id:birthdayWallId,type:2 };
        }else{
          // 生日吹蜡烛
          path = '/blowCandles';
          query = { id:birthdayWallId };
        }
      } else if(type === 'fuli_self') {
        // 360 我的套餐
        path = '/myWelfare';
      } else {
        // 360 首页
        path = '/';
      }

      useRouterReplace(path, query)
    }

    // 获取福利商城id
    const getWelfareLink = (companyId,urlJson) => {
      getCompanyConfigureByCompanyId({ companyId }).then((res) => {
          const { mallUrl } = res;
          if (mallUrl.indexOf("/") === 0) {
            let urlArr = mallUrl.split("?");
            let mallId = urlArr[1].split("=")[1];
            useLSSet("mallId", mallId);
          } else {
            useLSSet("mallUrl", mallUrl);
          }

          // 跳转页面
          goPage(urlJson)
        }
      );
    };


     // 微信环境登录
    const wxLogin = () => {
      const miniToken = useGetUrlParam("miniToken")
      const cardId = useGetUrlParam("cardId")
      const birthdayWallId = useGetUrlParam("birthdayWallId")
      if(birthdayWallId){
        toLogin(miniToken, { birthdayWallId })
      }else{
        toLogin(miniToken, { cardId })
      }
    }
    // 360登录 type = 360
    const toLogin = (miniToken, urlJson) => {
      useLSRemove();
      exchange({ miniToken }).then(res => {
        if (res) {
          const { accesstoken, subjectModel: { userInfoDto } } = res;
          useLSSet("miniToken", miniToken);
          useLSSet("tjToken", accesstoken);
          useGetMemberInfo(userInfoDto);
          getWelfareLink(userInfoDto.companyId, urlJson);
        }
      })
    }
    // 免登直接跳新体检
    const newTiJian = (miniToken,companyId) => {
      getCompanyConfigureByCompanyId({ companyId }).then(res => {
        const { indexType } = res;
        if (indexType === 6) {
          window.location.replace(`${useGetNewTiJianUrl()}/login?miniToken=${miniToken}&type=0`)      
        } else {
          const jumpUrl = `${useGetLuoDiUrl()}/transfer?miniToken=${miniToken}`;
          window.location.replace(jumpUrl)
        }
      })
    }
  
    // 钉钉免登
    const ddLogin = () => {
      const { corpId, appKey, type, cardId, ...params } = router.currentRoute.value.query;
      if (corpId) {
        dd.ready(function () {
          // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
          dd.runtime.permission.requestAuthCode({
            corpId: corpId,
            onSuccess: function (result) {
              if (result.code) {
                // dingLogin(result.code, cardId);
                dingLogin({ appId: appKey, authCode: result.code }).then(res => {
                  if (res) {
                    const { miniToken } = res;
                    if (type === '360') {
                      toLogin(miniToken, { cardId });
                    }
                    if(type==='360Birthday'){
                      toLogin(miniToken, { birthdayWallId: cardId});
                    }
                    if (type === 'kuakua') {
                      // 带上miniToken去夸夸 pageType  detailId
                      const { pageType, thumbId } = params;
                      const url = `${useGetKuaKuaUrl()}${stringify({ miniToken, pageType, thumbId })}`;
                      window.location.replace(url);
                    }
                    if(type === 'landPage') {  // 去落地页首页
                      const jumpUrl = `${useGetLuoDiUrl()}/transfer?miniToken=${miniToken}`;
                      window.location.replace(jumpUrl)
                      //toLogin(miniToken, {});
                    }
                  }
                })
              }
              /*{
                      code: 'hYLK98jkf0m' //string authCode
                  }*/
            },
            onFail: function (err) {
              alert("dd error: " + JSON.stringify(err));
            },
          });
        });
      } else {
        wxLogin()
      }

    };

    // 企业微信免登
    const wxworkLogin = () => {

      const { appId, code, type, state, id, ...params } = router.currentRoute.value.query;
      if(appId && code){
        qywxLogin({ cropId:appId , code}).then(res => {
          if (res) {
            const { miniToken, accesstoken, subjectModel: { userInfoDto } } = res;
            useLSSet("tjToken", accesstoken);
            if (type === '360') {
              toLogin(miniToken, { cardId:id });
            }
            if(type==='360Birthday'){
                toLogin(miniToken, { birthdayWallId: id});
            }
            if (type === 'kuakua') {
              // 带上miniToken去夸夸 pageType  detailId
              const { pageType } = params;
              const url = `${useGetKuaKuaUrl()}${stringify({ miniToken, pageType, thumbId: id })}`;
              window.location.replace(url);
            }
            if(type === 'landPage') { // 去落地页首页
              newTiJian(miniToken, userInfoDto.companyId)
              //toLogin(miniToken, {});
            }
          }
        })
      } else {
        wxLogin()
      }
    }

    // 飞书免登
    const feishuLogin = () => {
      const { appId, code, type, id,birthdayWallId, ...params } = router.currentRoute.value.query;
      if(appId && code){
        fsLogin({appId, code}).then(res => {
          if(res){
            const { miniToken } = res;

            if (type === '360') {

              toLogin(miniToken, { cardId: id,birthdayWallId });
            }

            if(type==='360Birthday'){
              toLogin(miniToken, { birthdayWallId: id});
            }
            if (type === 'kuakua') {
              // 带上miniToken去夸夸 pageType  detailId
              const { pageType } = params;
              const url = `${useGetKuaKuaUrl()}${stringify({ miniToken, pageType, thumbId: id })}`;
              window.location.replace(url);
            }
            if(type === 'landPage') { // 去落地页首页
              const jumpUrl = `${useGetLuoDiUrl()}/transfer?miniToken=${miniToken}`;
              window.location.replace(jumpUrl)
              //toLogin(miniToken, {});
            }
          }
        })
      }else{
        const { miniToken } = router.currentRoute.value.query;
        toLogin(miniToken);
      }
    }


    // 判断环境

    var ua = navigator.userAgent.toLowerCase();

    let tradeType;

    if (ua.match(/lark/i) == "lark") {
      // 飞书
      tradeType = 5;
      useLSSet("tradeType", tradeType);
      feishuLogin();
    } else if (ua.includes("dingtalk")) {
      // 钉钉
      tradeType = 4;
      useLSSet("tradeType", tradeType);
      console.log("dingding");
      ddLogin();
    } else if (ua.match(/wxwork/i) == "wxwork") {
      // 企业微信
      tradeType = 3;
      useLSSet("tradeType", tradeType);
      wxworkLogin();
    } else if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
      wx.miniProgram.getEnv((res) => {
        // 小程序环境
        if (res.miniprogram) {
          tradeType = 2;
          useLSSet("tradeType", tradeType);
        } else {
          console.log("在微信，不在小程序里");
          tradeType = 1;
          useLSSet("tradeType", tradeType);
        }
      });
      wxLogin()
    } else {
      tradeType = 0;
      useLSSet("tradeType", tradeType);
      console.log("啥也不是,普通浏览器");
      // 便于浏览器调试
      wxLogin()
    }



    return {};
  },
};
</script>

<style lang="scss" scoped>
.login {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.auto-home__main {
  user-select: none;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .auto-home__title {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .auto-home__sub-title {
    color: #fff;
    font-size: 12px;
  }
}
</style>

import axios from "axios";
import { Toast } from "vant";

axios.create({
  timeout: 5000,
});
//不同环境请求不同环境接口地址
// if (location.hostname == "ybhcare.wanhuahengxin.com") {
//   axios.defaults.baseURL = "https://jk.wanhuahengxin.com/api/";
// } else if(location.hostname == "uatybhcare.wanhuahengxin.com") {
//   axios.defaults.baseURL = "https://tjk.wanhuahengxin.com/api/";
// }else if(location.hostname == "devybhcare.wanhuahengxin.com"){
//   axios.defaults.baseURL = "https://djk.wanhuahengxin.com/api/";
// }else{
//   axios.defaults.baseURL = "https://tjk.wanhuahengxin.com/api/";
// }
axios.defaults.baseURL = `${process.env.VUE_APP_GROUP_API}/api/`;
axios.interceptors.request.use(
  (config) => {
    config.params = { ...config.params };

    config.headers["jwttoken"] = localStorage.getItem("tjToken");

    config.headers["Content-Type"] = "application/json;charset=UTF-8";

    return config;
  },
  (error) => {
    //请求错误处理
    Toast.fail(error);
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    let result;
    switch (res.status) {
      case 404:
        console.log("404");
        break;
      case 500:
        console.log("服务器出错");
        break;
      case 401:
        console.log("401");
        break;
      case 200:
        if (res) {
          const { data } = res;
          if (data.code == 0) {
            Toast.clear();
            result = data.data || data.data === 0 ? data.data : data.msg;
          } else {
            Toast({ message: data.msg, duration: 2000 });
          }
        } else {
          Toast({ message: "出错啦", duration: 2000 });
        }

        break;
    }
    //在这里对返回的数据进行处理
    return result;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios; //暴露axios实例

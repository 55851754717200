import Vrouter from "@/router";
import { useLSGet } from './useLocalStorage'
import { stringify  } from "qs";
import { useGetLuoDiUrl, useGet360Url, useGetVoucherUrl } from '@/hook/useGetJumpUrl'
export function useRouterPush(path, query = {}) {
  const router = Vrouter;
  router.push({ path, query });
}
export function useRouterReplace(path, query = {}) {
  const router = Vrouter;
  router.replace({ path, query });
}

export function useLinkToOther(query = {}) {
  const miniToken = useLSGet('miniToken')
  const mallId = useLSGet('mallId')
  const { pakageId, welfareType } = query
  const doMain = `${useGetLuoDiUrl()}/transfer?`;
  let params = {
    miniToken, to: 'modularPage'
  }
  if (pakageId === 0 || !pakageId) {
    if (!mallId) {
      const mallUrl = useLSGet('mallUrl')
      window.open(mallUrl)
    }
    params.id = mallId
  } else {
    params.pakageId = pakageId;
    params.welfareType = welfareType;
  }
  const link = `${doMain}${stringify(params)}`
  // console.log(link)
  window.location.href = link;
  // let url = 'miniToken=f89ef1f1e1b4f3eebfeab7e3b3edb5bb0a&to=modularPage&pakageId=16'
}
// 贺卡按钮跳转
export function useCardBtnLink(welfareType,packageAccountId,careType,cardId) {
  // careType 关怀类型(1-普通关怀；2-生日关怀；3-入职关怀)
  const miniToken = useLSGet('miniToken')
  const mallId = useLSGet('mallId')
  // 判断跳转链接
  if(welfareType == 1){
    // 跳领取红包
    return `${useGet360Url()}/redPacket?id=${cardId}&type=1&mallId=${mallId}&pakageId=${packageAccountId}`
   
    // return `${useGetLuoDiUrl()}/transfer?miniToken=${miniToken}&to=modularPage&id=${mallId}&pakageId=${packageAccountId}`
    // if(packageAccountId == 0){  
    //   // 福利类型是积分,点击领取福利按钮,跳转到福利商城页面
    //   return `${useGetLuoDiUrl()}/transfer?miniToken=${miniToken}&to=modularPage&id=${mallId}`
    // }else if (packageAccountId != 0){
    //   // 福利类型是积分,点击领取福利按钮,跳转到积分兑换专区
    //   return `${useGetLuoDiUrl()}/transfer?miniToken=${miniToken}&to=modularPage&pakageId=${packageAccountId}&welfareType=${welfareType}`
    // }
  }else if(welfareType == 2){ // 福利类型是套餐,点击领取福利按钮,跳转到套餐专区
    return `${useGetLuoDiUrl()}/transfer?miniToken=${miniToken}&to=modularPage&pakageId=${packageAccountId}&welfareType=${welfareType}`
  }
}
// 卡券按钮跳转
export function useVoucherBtnLink(provicerCode,cardNo,cardSecret) {
  return `${useGetVoucherUrl()}?provicerCode=${provicerCode}&cardNo=${cardNo}&cardSecret=${cardSecret}`
}

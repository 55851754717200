// 引入 get 和 post 请求
import { get, post,filePost } from "../utils/http";

// miniToken换取token 登录接口
export const exchange = (params) => {
  return post("wfmall/mall/v1/member/exchange", params);
};
// 查询用户个人信息
export const getMemberInfo = (params) => {
  return post("wfmall/mall/v1/member_info/get_member_info", params);
};
//查看关怀列表
export const getCardListo = (params) => {
  return get("wfmall/mall/v1/card/getCardList", params);
};
// 查看关怀记录
export const getCareRecordList = (params) => {
  return get("wfmall/mall/v1/card/getCareRecordList", params);
};

// 钉钉免登
export const dingLogin = (params) => {
  return post("/wfthird/thirdserver/v1/open/dingtalk/login", params);
};
// 企业微信免登
export const qywxLogin = (params) => {
  return post("/wfthird/thirdserver/v1/open/wx/wxLogin", params);
};
// 飞书免登
export const fsLogin = (params) => {
  return post("/wfthird/thirdserver/v1/open/fs/login", params);
};

// 云之家免登
export const yzjLogin = (params) => {
  return get("/wfthird/thirdserver/v1/open/ch/login", params);
};
// 商城链接跳转查询
export const getCompanyConfigureByCompanyId = (params) => {
  return get("/wfmall/mall/v1/company_configure/get_by_company_id", params);
};
// 查询福利套餐列表
export const getPakageList = () => {
  return get("/wfmall/mall/v1/welfare/getPakages");
};
// 查询积分套餐列表
export const getIntegralList = () => {
  return get("/wfmall/mall/v1/welfare/getIntegrals");
};
// 查询可用积分积分
export const getMemberTotalIntegrals = () => {
  return get("/wfmall/mall/v1/card/getMemberTotalIntegrals");
};
// 微信授权登录
export const wxLogin = (params) => {
  return post("/wfthird/thirdserver/v1/open/weChat/wxLogin", params);
};
// 绑定手机号
export const bindPhone = (params) => {
  return post("/wfthird/thirdserver/v1/open/weChat/bindPhone", params);
};
// 查询企业
export const getCompanyList = params => {
  return post('/wfmall/mall/v1/open/get_company_list',params);
};
//  员工验证码登录发送验证码
export const sendCodeLogin = params => {
  return post('wfmall/mall/v1/open/send_code_login',params);
};
// 用户获取贺卡数据接口
export const getCardDetail = (params) => {
  return get("/wfmall/mall/v1/card/getDetail", params);
};
// 预览获取贺卡数据接口
export const getPreviewCardDetail = (params) => {
  return get("/wfmall/mall/v1/open/previewCard", params);
};
// 生日墙点赞(已点赞的变为取消点赞)
export const birthdayGiveLike = params => {
  return post('wfmall/mall/v1/birthday/birthdayGiveLike',params);
};
// 分页查看生日墙已收到的祝福语
export const findBirthdayMemberGreetingPage = params => {
  return post('wfmall/mall/v1/birthday/findBirthdayMemberGreetingPage',params);
};
//  分页查看生日墙手气榜
export const findBirthdayMemberRedPackagePage = params => {
  return post('wfmall/mall/v1/birthday/findBirthdayMemberRedPackagePage',params);
};
// 分页查询生日墙
export const findBirthdayPage = params => {
  return post('wfmall/mall/v1/birthday/findBirthdayPage',params);
};
// 分页查询企业后台已创建的生日墙祝福语
export const findPageGreeting = params => {
  return post('wfmall/mall/v1/birthday/findPageGreeting',params);
};
// 生日墙领取红包
export const getBirthdayRedPackage = params => {
  return post('wfmall/mall/v1/birthday/getBirthdayRedPackage',params);
};
// 查看生日墙详情
export const getBirthdayWallDetail = params => {
  return get('wfmall/mall/v1/birthday/getBirthdayWallDetail',params);
};
// 生日墙送祝福
export const giveBirthdayGreeting = params => {
  return post('wfmall/mall/v1/birthday/giveBirthdayGreeting',params);
};
// 生日墙首页查询员工信息
export const getBirthdayMemberInfo = params => {
  return get('wfmall/mall/v1/birthday/getBirthdayMemberInfo',params);
};

// 用户查看详情前领取红包页面
export const showBirthdayRedpackge = params => {
  return get('wfmall/mall/v1/birthday/showBirthdayRedpackge',params);
};
//  查询用户生日信息
export const getBirthCareInfoe = params => {
  return get('wfmall/mall/v1/card/getBirthCareInfo',params);
};
// 用于预览查询生日墙信息

export const getBirthdayWallDetailOpen = params => {
  return get('wfmall/mall/v1/open/getBirthdayWallDetailOpen',params);
};
// 领取关怀的生日红包
export const getRedPackage = params => {
  return get('wfmall/mall/v1/card/getRedPackage',params);
};
// 卡券获取贺卡数据接口
export const getGreetingCard = (params) => {
  return get("/wfmall/open/v1/entityCard/getGreetingCard", params);
};
